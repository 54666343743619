import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import facebook from '../../assets/facebook.svg'
import x from '../../assets/x.svg'
import instagram from '../../assets/instagram.svg'
import youtube from '../../assets/youtube.svg'
import snapchat from '../../assets/snapchat.svg'
import tiktok from '../../assets/tiktok.svg'
import linkIcon from '../../assets/link-icon.png'
import logo from '../../assets/logo.svg'
import './style.css'
import { Link } from "react-router-dom";
import SocialMediaService from "../../services/SocialMediaService";
import HomeService from "../../services/HomeService";

const Footer = () => {
    const [barcode, setBarcode] = useState('')
    const [data, setData] = useState( {
        instagram:'',
        twitter:'',
        facebook:'',
        youtube:''
    })

    const socialMediaService = new SocialMediaService()
    const homeService = new HomeService()

    useEffect(()=>{
        socialMediaService?.getList().then(res=>{
        if(res?.status === 200){
          setData(res?.data?.data)
        }
    })
    
        homeService?.getBarcode().then(res=>{
        if(res?.status === 200){
            setBarcode(res?.data?.data?.url)
        }
      })
    },[])

    return <div className="footer">
        <div className="container">
            <Row>
            <Col md={4} sm={12} className="logo">
                    <h2 className="logo-title">Hasan Al Naser <sup className="sup" style={{ top: '-25px', fontSize: '16px', left: '-57px' }}>Trad co</sup></h2>
                    <img src={logo} alt='logo' className="logo-img" width={150}/>
                    <ul className="nav-paths">
                        <li>
                            <Link to='/'>Home</Link>
                        </li>
                        <li>
                            <Link to='/preumium-products'>Premium Products</Link>
                        </li>
                        <li>
                            <Link to='/high-pressure'>High Pressure</Link>
                        </li>
                        <li>
                            <Link to='/about-us'>About</Link>
                        </li>
                    </ul>
                </Col>
                <Col md={4} sm={12} className="social d-block">
                    <img src={barcode} alt='Qr' className="qr" width={80}/>
                    <div>
                        <div className="icons">
                            {!!data?.facebook && <a rel="noreferrer" href={data?.facebook} target='_blank'>
                                <img src={facebook} alt='facebook' />
                            </a>}
                            {!!data?.instagram && <a rel="noreferrer" href={data?.instagram} target='_blank'>
                                <img src={instagram} alt='instagram' />
                            </a>}
                            {!!data?.twitter && <a rel="noreferrer" href={data.twitter} target='_blank'>
                                <img src={x} alt='x' />
                            </a>}
                            {!!data?.you_tube && <a rel="noreferrer" href={data?.you_tube} target='_blank'>
                                <img src={youtube} alt='youtube' />
                            </a>}
                            {!!data?.snapchat && <a rel="noreferrer" href={data?.snapchat} target='_blank'>
                                <img src={snapchat} alt='snapchat' />
                            </a>}
                            {!!data?.tiktok && <a rel="noreferrer" href={data?.tiktok} target='_blank'>
                                <img src={tiktok} alt='tiktok' />
                            </a>}
                        </div>
                        <div className="mobile-hidden">
                            <p className="text-center mb-0 power">All Rights reserved &copy; Hasan Al Naser
                             <span className="mx-1">{new Date().getFullYear()}</span></p>
                            <p className="text-center mb-0 power mt-1">Powered By 
                            <a rel="noreferrer" 
                                href='https://www.cloudliftsolution.com/' 
                                target='_blank'
                                style={{
                                    color: '#fff',
                                    textDecoration: 'none',
                                    margin: '0 2px'
                                }}
                            >Cloud Lift Solutions</a>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={12} className="apps">
                    <div>
                        <div className="icons">
                            {data?.gmail && <p>
                                <i className="la la-envelope me-2"></i> 
                                {data?.gmail}
                            </p>}
                            {data?.call_us && <p>
                                <i className="la la-phone me-2"></i> 
                                {data?.call_us}
                            </p>}
                            {data?.address && <p>
                                <i className="la la-map-marker me-2"></i> 
                                {data?.address}
                            </p>}
                            {(data?.time_from || data?.time_to) && <p className="mb-0">
                                <i className="la la-clock me-2"></i> 
                                {data?.time_from} - {data?.time_to}
                            </p>}
                        </div>
                    </div>
                </Col>
                <Col md={12} sm={12} className="mobile-view text-center">
                    <a 
                        href="https://www.masterhn.com" 
                        style={{
                            color: '#fff',
                            textDecoration: 'none',
                            margin: '8px 2px 0'
                        }}
                        target="_blank"
                        className="mb-4 nav-mobile-logo" 
                        rel="noreferrer"
                    >
                        <img src={linkIcon} alt='linkIcon' width={20} className="mx-2" />
                        Master HN
                    </a>
                    <p className="text-center mb-0 power">All Rights reserved &copy; Hasan Al Naser
                        <span className="mx-1">{new Date().getFullYear()}</span></p>
                    <p className="text-center mb-0 power mt-1">Powered By 
                    <a rel="noreferrer" 
                        href='https://www.cloudliftsolution.com/' 
                        target='_blank'
                        style={{
                            color: '#fff',
                            textDecoration: 'none',
                               margin: '0 2px'
                        }}
                    >Cloud Lift Solutions</a>
                    </p>
                </Col>
                
            </Row>
        </div>
    </div>
}
export default Footer;
